@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("/src/assets/font/Basier-Square-regular-webfont/stylesheet.css");

.light {
  --dark-primery: 223, 144, 27; /*#DF901B */
  --transaction-bg: 112, 204, 74, 1; /* #70CC4A  icon-btn */
  --button-hover-bg: 91, 170, 60, 1; /* #5BAA3C icon-btn-hover */
  --button-bg: 112, 204, 74, 1; /* #70CC4A */
  --button-text: 23, 44, 15, 1; /* #172c0f Monfi/text-btn-primary */
  --input-bg: 232, 232, 232, 1; /* #e8e8e8  Monfi input-inactive */
  --active-state-bg: 112, 204, 74, 0.5; /* #70CC4A */
  --body-bg: 244, 244, 244, 1; /* #F4F4F4 */
  --body-text: 23, 44, 15, 1; /* #172c0f Monfi/text-primary */
  --highlighter-green: 22, 126, 66, 1; /* #167E42 */
  --divider-border: 229, 229, 231, 1; /* #E5E5E7 */
  --popup-bg: 255, 255, 255, 1; /* #FFFFFF */
  --active-dropdown: 233, 232, 227, 1; /* #E9E8E3 */
  --dropdown-popup-bg: 255, 255, 255, 1; /* #FFFFFF */
  --highlighter-red: 236, 12, 0; /* #EC0C00 */
  --hover-state-bg: 223, 219, 211, 1; /* #DFDBD3 */
  --icon-btn: 112, 204, 74, 1; /* #70CC4A */
  --icon-btn-hover: 112, 204, 74, 0.5; /* #70CC4A */
  --input-active-bg: 218, 219, 218, 1; /* #dadbda */
  --option-list-hover: 247, 246, 244, 1; /* #F7F6F4 */
  --option-list-selected: 241, 250, 237, 1; /* #f1faed Monfi/activeselection */
  --paragraph-text: 79, 89, 75, 1; /* #4f594b Monfi/label-text */
  --placeholder-text: 23, 44, 15, 0.5; /* new color from monfi #172c0f */
  --primary-item: 23, 44, 15, 1; /*#172C0F  Monfi/TextSecondary */
  --primary-Icon-bg: 233, 232, 227; /* #E9E8E3 */
  --secondary-icon-bg: 209, 209, 209, 1; /* #d1d1d1 custome color */
  --tabs-gradient: 255, 255, 255, 1; /* #FFFFFF */
  --search-bg: 216, 219, 216, 1; /* new color from monfi #d8dbd8*/
  --transactions-icon-bg: 107, 122, 101, 1; /*#6b7a65*/
  --skeleton-start: 249, 249, 246, 1; /* #F9F9F6 */
  --skeleton-end: 107, 122, 101, 0.2; /* #6b7a65 with opacity*/


  --primarycard-gradient: linear-gradient(
    98deg,
    #f7f7f7 3.96%,
    #fff 49.22%,
    #f7f7f7 95.85%
  ); 

  --side-gradient: linear-gradient(
    0deg,
    #253d35 20.71%,
    #253d35 24.74%,
    #3a545356 100%
  );
}

.dark {
  --dark-primery: 239, 198, 96, 1; /*#EFC660 */
  --option-list-selected: 70 95 59; /* #465f3b Monfi/activeselection */
  --transaction-bg: 112, 204, 74, 1; /* #70CC4A  icon-btn */
  --button-hover-bg: 46, 85, 30, 1; /* #2E551E icon-btn-hover */
  --button-bg: 112, 204, 74; /* #70CC4A  icon-btn */
  --button-text: 23, 44, 15, 1; /* #172c0f Monfi/text-btn-primary */
  --input-bg: 39, 42, 40, 1; /* #272a28 Monfi input-inactive */
  --active-state-bg: 112, 204, 74, 0.5; /* #70CC4A */
  --body-bg: 15, 15, 15, 1; /* #0F0F0F */
  --body-text: 231, 238, 235, 1; /* #e7eeeb Monfi/text-primary */
  --highlighter-green: 21, 191, 93, 1; /* #15BF5D */
  --divider-border: 58, 58, 57, 1; /* #3A3A39 */
  --highlighter-red: 254, 67, 57; /* #FE4339 */
  --hover-state-bg: 28, 42, 37, 1; /*#1C2A25 #  Monfi/*/
  --icon-btn: 112, 204, 74, 1; /* #70CC4A */
  --icon-btn-hover: 112, 204, 74, 0.5; /* #70CC4A */
  --paragraph-text: 209, 209, 209, 1; /* #97a791 Monfi/label-text */
  --placeholder-text: 129, 136, 126, 0.5; /* new color from monfi #81887e */
  --popup-bg: 32, 32, 30, 1; /* #20201E */
  --active-dropdown: 63, 63, 59, 1; /* #3F3F3B */
  --primary-item: 177, 186, 173, 1; /*#B1BAAD #  Monfi/TextSecondary*/
  --primary-Icon-bg: 28, 42, 37, 1; /* #1C2A25 */
  --input-active-bg: 62, 66, 62, 1; /* #3e423e */
  --secondary-icon-bg: 58, 61, 59, 1; /* #3a3d3b custom color */
  --dropdown-popup-bg: 32, 32, 30, 1; /* #20201E */
  --option-list-hover: 70, 95, 59, 1; /* #465f3b */
  --primary-shade-color: 239, 198, 96, 0.3; /* ##EFC6604D */
  --skeleton-start: 63, 63, 59, 1; /* #3F3F3B */
  --skeleton-end: 70, 95, 59, 0.3; /* #465f3b  with opacity*/
  --transactions-icon-bg: 68, 75, 65, 1; /*#444b41*/
  --search-bg: 39, 42, 40, 1; /* new color from monfi #272a28*/
  --tabs-gradient: linear-gradient(
    95deg,
    #1b1b19 17.71%,
    #292928 51.74%,
    #1b1b19 89.56%
  );
  --custome-primarycard-gradient: linear-gradient(
    180deg,
    #253d35 0%,
    #3a545356 100%
  );


  /* --primarycard-gradient: linear-gradient(
    98deg,
    #1a1a18 3.96%,
    #20201e 52.42%,
    #1a1a18 95.85%
  ); */

  --primarycard-gradient: linear-gradient(180deg, #253d35 0%, #3a545356 100%);

  --side-gradient: linear-gradient(
    0deg,
    #253d35 20.71%,
    #253d35 24.74%,
    #3a545356 100%
  );
}

/* Keyframes for the skeleton animation */
@keyframes changeColor-light {
  0% {
    background: linear-gradient(90deg, rgba(249, 249, 246, 1) 0%, #dadbda 46%);
  }
  40% {
    background: linear-gradient(90deg, rgba(249, 249, 246, 1) 20%, #dadbda 66%);
  }
  60% {
    background: linear-gradient(90deg, rgba(249, 249, 246, 1) 20%, #dadbda 66%);
  }
  80% {
    background: linear-gradient(90deg, #dadbda 40%, rgba(249, 249, 246, 1) 76%);
  }
  100% {
    background: linear-gradient(90deg, rgba(249, 249, 246, 1) 80%, #dadbda 96%);
  }
}
@keyframes changeColor-dark {
  0% {
    background: linear-gradient(
      90deg,
      rgba(209, 209, 209, 0.35) 0%,
      /* rgba(23, 44, 15, 1) 46% */ rgba(70, 95, 59, 0.3) 46%
    );
  }
  40% {
    background: linear-gradient(
      90deg,
      rgba(209, 209, 209, 0.35) 20%,
      /* rgba(23, 44, 15, 1) 66% */ rgba(70, 95, 59, 0.3) 66%
    );
  }
  60% {
    background: linear-gradient(
      90deg,
      rgba(209, 209, 209, 0.35) 20%,
      /* rgba(23, 44, 15, 1) 66% */ rgba(70, 95, 59, 0.3) 66%
    );
  }
  80% {
    background: linear-gradient(
      90deg,
      rgba(209, 209, 209, 0.35) 40%,
      /* rgba(23, 44, 15, 1) 76% */ rgba(70, 95, 59, 0.3) 76%
    );
  }
  100% {
    background: linear-gradient(
      90deg,
      rgba(209, 209, 209, 0.35) 80%,
      /* rgba(23, 44, 15, 1) 96% */ rgba(112, 204, 74, 0.1) 96%
    );
  }
}

/* Apply the animation to the element */
.light .skeleton-animation {
  animation: changeColor-light 2s ease infinite; /* Change the duration (3s) as needed */
}
.dark .skeleton-animation {
  animation: changeColor-dark 2s ease infinite; /* Change the duration (3s) as needed */
}
/* Hide scrollbar for all browsers 
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;  
}
::-webkit-scrollbar {
  display: none;
}

*/

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar {
  scrollbar-color: transparent, transparent;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #d9d9d9;
}

/* For IE, Edge, and Firefox */
html,
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  overflow: scroll; /* Ensure scrolling is enabled */
}

/* Custom CSS to hide arrows */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-arrows {
  -moz-appearance: textfield;
}

/* Hide scrollbar for all browsers */
.hide-scrollbar {
  /* Chrome, Safari, and Opera */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  overflow: -moz-scrollbars-none; /* Firefox */
  /* Hide scrollbar for all other browsers */
  /* overflow: hidden;  */
}

.light .autofill-input {
  transition: background-color 5000s ease-in-out 0s;
}

.light .autofill-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #f0f0f0 to your desired background color */
  -webkit-text-fill-color: #000 !important; /* Change #000 to your desired text color */
}

.light .autofill-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #000 to your desired background color */
  -webkit-text-fill-color: #000 !important; /* Change #000 to your desired text color */
}

.light .autofill-input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #000 to your desired background color */
  -webkit-text-fill-color: #000 !important; /* Change #000 to your desired text color */
}

.dark .autofill-input {
  transition: background-color 5000s ease-in-out 0s;
}

.dark .autofill-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #f0f0f0 to your desired background color */
  -webkit-text-fill-color: #ffff !important; /* Change #ffff to your desired text color */
}

.dark .autofill-input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #ffff to your desired background color */
  -webkit-text-fill-color: #ffff !important; /* Change #ffff to your desired text color */
}

.dark .autofill-input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #f0f0f000 inset !important; /* Change #ffff to your desired background color */
  -webkit-text-fill-color: #ffff !important; /* Change #ffff to your desired text color */
}

/* Others Accounts Border Animations */
@keyframes borderAnimation {
  0% {
    border-top-color: #70cc4a;
    border-right-color: #15bf5d;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  25% {
    border-top-color: transparent;
    border-right-color: #70cc4a;
    border-bottom-color: #15bf5d;
    border-left-color: transparent;
  }
  50% {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #70cc4a;
    border-left-color: #15bf5d;
  }
  75% {
    border-top-color: #15bf5d;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #70cc4a;
  }
  100% {
    border-top-color: #15bf5d;
    border-right-color: #70cc4a;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

.borderAnimation:hover {
  animation: borderAnimation 2s linear infinite;
  border-style: solid;
  border-width: 1px;
  border-color: transparent; /* Initial color */
}

.slick-dots li button:before {
  @apply text-body-text !important;
}

/* Change the color of the active dot */
.slick-dots li.slick-active button:before {
  @apply text-primary-item !important;
}

.slick-slide > div {
  margin: 0 10px;
}

@layer utilities {
  @keyframes breathing {
    0%,
    100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.7;
    }
  }

  .animate-breathing {
    animation: breathing 2s infinite;
  }

  @keyframes progress {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .animate-progress {
    animation: progress 1.5s linear infinite;
  }
}

.monfi-light {
  --primary: #70cc4a;
  --text-primary: #172c0f;
  --label-text: #4f594b;
  --placeholder-text: #172c0f;
  --input-active: #dadbda;
  --input-inactive: #e8e8e8;
  --text-secondary: #6a726c;
  --text-btn-primary: #172c0f;
  --brand-bg: #122841;
  --activeselection: #f1faed;
  --borderColour: #eaeeeb;
  --tabs-inactive: #b5bbb3;
  --transactions-icon-bg: #6b7a65;
  --new-btn-bg: #1c2a25;
  --search-bg: #d8dbd8;
}

.monfi-dark {
  --primary: #70cc4a;
  --text-primary: #e7eeeb;
  --label-text: #97a791;
  --placeholder-text: #81887e;
  --input-active: #3e423e;
  --input-inactive: #272a28;
  --text-secondary: #b1baad;
  --text-btn-primary: #172c0f;
  --brand-bg: #70cc4a;
  --activeselection: #465f3b;
  --borderColour: #303331;
  --tabs-inactive: #9ea59b;
  --transactions-icon-bg: #444b41;
  --search-bg: #272a28;
  --new-btn-bg: #1c2a25;
}
